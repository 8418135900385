.textbox { 
    height:8vh; 
    width: 25vw;
    padding-left: 2.5vw;
    padding-right: 2.5vw;
    font: 1.75vw -apple-system, BlinkMacSystemFont, sans-serif;
    font-weight: 600;
    -webkit-border-radius: 10vw; 
    -moz-border-radius: 10vw; 
    border-radius: 10vw; 
    text-align: left;
    -webkit-text-size-adjust: 100%;
    outline: none; 
    border: none;
    transition: 0.4s;
}

.textbox:focus {
    width: 30vw; 
}

.submitbutton {
    margin-top: 2.5vh;
    height:8vh; 
    width: 20vw; 
    font: 1.5vw -apple-system, BlinkMacSystemFont, sans-serif;
    font-weight: 700;
    -webkit-border-radius: 10vw; 
    -moz-border-radius: 10vw; 
    border-radius: 10vw;
    background-color: white;
    color: #05445E;
    text-align: center;
    -webkit-text-size-adjust: 100%;
    outline: none;  
    border: none;
    transition: 0.1s;    
}

.submitbutton:active {
    color: white;
}


@media (max-width:  414px) {
    .textbox { 
        font: 5vw -apple-system, BlinkMacSystemFont, sans-serif;
        height: 8vh; 
        width: 50vw;
        padding-left: 5vw;
        padding-right: 5vw;
    }
    .textbox:focus {
        width: 55vw;
    }

    .submitbutton {
        font: 4vw -apple-system, BlinkMacSystemFont, sans-serif;
        font-weight: 700;
        height: 8vh; 
        width: 50vw;
    }
}