body {
    overflow: hidden;
    height: 100vh;
    background-image: url('./background.svg');
    background-size:100vh 100vw;
    -o-background-size: 100vh 100vw;
    -webkit-background-size: 100vh 100vw;
    background-position: top center;
    background-repeat: no-repeat;
    background-size:cover;
}

.hero-text {
    color: white;
    justify-content: center;
}

.brand {
    font-size: 7vw;
}

.hero {
    margin: 0;
    margin-left: 27vw;
    margin-top: 10vh;
    margin-right: 27vw;
    font-size: 3vw;
}

.tag {
    margin: 0;
    margin-bottom: 10vh;
    font-size: 2vw;
}

@media (max-width: 414px) {
    .brand {
        font-size: 15vw;
    }

    .hero {
        font-size: 6vw;
        margin-left: 13vw;
        margin-right: 13vw;
    }

    .tag {
        font-size: 4vw;
    }
}

